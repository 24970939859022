import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { ApiService } from '@app/core/services';
import { Client } from '@app/core/types';
import { filterSuccess } from '@app/core/utils';
import { map } from 'rxjs/operators';

export const clientResolver: ResolveFn<Client[]> = (route, state) => {
	return inject(ApiService)
		.getClients()
		.pipe(
			filterSuccess<Client[]>(),
			map(response => response.data)
		);
};
