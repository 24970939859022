import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderComponent } from '@app/core/components/loader';
import { AuthService } from '@app/core/services';
import { delay, of } from 'rxjs';

@Component({
	selector: 'app-home',
	standalone: true,
	imports: [LoaderComponent],
	templateUrl: './home.component.html',
	styleUrl: './home.component.scss',
})
export class HomeComponent {
	#auth = inject(AuthService);
	#router = inject(Router);

	constructor() {
		of(true)
			.pipe(delay(2500))
			.subscribe(() => {
				if (this.#auth.isAuth) {
					this.#router.navigate(['/app', 'new-chat']);
				}
			});
	}
}
