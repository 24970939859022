import { Routes } from '@angular/router';
import { authGuard, subscriptionGuard } from '@app/core/guards';
import { clientResolver } from '@app/core/resolvers';
import { HomeComponent } from '@app/views/home';

export const routes: Routes = [
	{
		path: '',
		redirectTo: 'home',
		pathMatch: 'full',
	},
	{
		path: 'home',
		component: HomeComponent,
		data: {
			title: 'Home',
			description: 'Home page',
		},
	},
	{
		path: 'app',
		loadComponent: () =>
			import('@app/core/components/layout').then(m => m.LayoutComponent),
		children: [
			{
				path: '',
				redirectTo: 'new-chat',
				pathMatch: 'full',
			},
			{
				path: 'chats-list',
				loadComponent: () =>
					import('@app/views/chats-list').then(m => m.ChatsListComponent),
				data: {
					title: 'Chats List',
					description: 'Chats List page',
				},
				resolve: {
					clients: clientResolver,
				},
			},
			{
				path: 'new-chat',
				loadComponent: () =>
					import('@app/views/new-chat').then(m => m.NewChatComponent),
				data: {
					title: 'New Chat',
					description: 'New Chat page',
				},
				resolve: {
					clients: clientResolver,
				},
			},
			{
				path: 'chat/:id',
				loadComponent: () =>
					import('@app/views/chat').then(m => m.ChatComponent),
				data: {
					title: 'Chat',
					description: 'Chat page',
				},
			},
		],
		canActivate: [authGuard, subscriptionGuard],
		canActivateChild: [authGuard, subscriptionGuard],
	},
	{
		path: 'billing',
		loadComponent: () =>
			import('@app/views/billing').then(m => m.BillingComponent),
		data: {
			title: 'Billing',
			description: 'Billing page',
		},
	},
	{
		path: '**',
		redirectTo: 'app',
		pathMatch: 'full',
	},
];
