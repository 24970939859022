import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { NotifyService } from '@app/core/services';
import { AppStore } from '@app/core/store';
import { catchError, throwError } from 'rxjs';
import { finalize } from 'rxjs/operators';

export const requestInterceptor: HttpInterceptorFn = (req, next) => {
	const notify = inject(NotifyService);
	const router = inject(Router);
	const { setIsFetching, setIsSummiting } = inject(AppStore);

	if (['GET', 'DELETE'].includes(req.method)) {
		setIsFetching(true);
	}

	if (['POST', 'PUT'].includes(req.method)) {
		setIsSummiting(true);
	}

	return next(req).pipe(
		catchError(error => {
			if (error.status === 404) {
				// Show info not found notification
				notify.notify('notification.info.notfound', 'info');

				// Redirect to new chat page
				router.navigate(['/app', 'new-chat']);
			}

			if (error.status === 400 || error.status === 422) {
				// Show error request notification
				notify.notify('notification.error.request', 'error');
			}

			if (error.status === 500) {
				// Show error server notification
				notify.notify('notification.error.server', 'error');
			}

			return throwError(() => error);
		}),
		finalize(() => {
			if (['GET', 'DELETE'].includes(req.method)) {
				setIsFetching(false);
			}

			if (['POST', 'PUT'].includes(req.method)) {
				setIsSummiting(false);
			}
		})
	);
};
