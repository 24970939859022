import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from '@app/app.component';
import { appConfig } from '@app/app.config';
import { environment } from '@app/env';
import * as Sentry from '@sentry/angular';

Sentry.init({
	dsn: 'https://b56dbdd20ade02ac8f101912a41160ea@o1142010.ingest.us.sentry.io/4508127038078976',
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.browserProfilingIntegration(),
		Sentry.replayIntegration(),
	],
	// Tracing
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	// Set profilesSampleRate to 1.0 to profile every transaction.
	// Since profilesSampleRate is about tracesSampleRate,
	// the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
	// For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
	// result in 25% of transactions being profiled (0.5×0.5=0.25)
	profilesSampleRate: 1.0,
	environment: environment.PRODUCTION ? 'production' : 'development',
	release: environment.APP_VERSION,
});

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
