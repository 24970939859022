import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService, NotifyService } from '@app/core/services';
import { environment } from '@app/env';
import { WA_WINDOW } from '@ng-web-apis/common';
import { catchError, throwError } from 'rxjs';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
	const authService = inject(AuthService);
	const window = inject(WA_WINDOW);
	const notify = inject(NotifyService);

	if (authService.isAuth) {
		req = req.clone({
			setHeaders: { Authorization: `Bearer ${authService.JWT}` },
		});
	}

	return next(req).pipe(
		catchError(error => {
			if (error.status === 401 || error.status === 403) {
				// Show error auth notification
				notify.notify('notification.error.auth', 'warning');

				// Redirect to login page with current URL as query param
				const currentUrl = window.location.href;
				window.location.href = `${environment.MAIN_DOMAIN}/auth/login?returnUrl=${encodeURIComponent(currentUrl)}&callback=external`;
			}

			return throwError(() => error);
		})
	);
};
