import { DATE_PIPE_DEFAULT_OPTIONS, registerLocaleData } from '@angular/common';
import {
	HttpClient,
	provideHttpClient,
	withFetch,
	withInterceptors,
} from '@angular/common/http';
import {
	APP_INITIALIZER,
	ApplicationConfig,
	ErrorHandler,
	LOCALE_ID,
	provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import {
	provideClientHydration,
	withEventReplay,
	withHttpTransferCacheOptions,
} from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
	Router,
	provideRouter,
	withComponentInputBinding,
	withInMemoryScrolling,
	withRouterConfig,
	withViewTransitions,
} from '@angular/router';
import { authInterceptor, requestInterceptor } from '@app/core/interceptors';
import {
	CustomRouterStateSerializer,
	RouterEffects,
} from '@app/core/router-store';
import { AuthService, LanguageService, ThemeService } from '@app/core/services';
import { environment } from '@app/env';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { TranslateLoader, provideTranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { routes } from './app.routes';

import localeEN from '@angular/common/locales/en';
import localeES from '@angular/common/locales/es';
import * as Sentry from '@sentry/angular';
import LogRocket from 'logrocket';
import { provideMarkdown } from 'ngx-markdown';
import { provideNgxStripe } from 'ngx-stripe';

registerLocaleData(localeEN);
registerLocaleData(localeES);

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function initializeApp(
	language: LanguageService,
	theme: ThemeService,
	auth: AuthService
) {
	return () => {
		LogRocket.init('drwbnn/jfaba-notes');
		return new Promise<void>(resolve => {
			language.init();
			theme.init();
			auth.init();
			if (auth.isAuth) {
				LogRocket.identify(auth.authUser.id, auth.authUser);
				Sentry.setUser(auth.authUser);
				LogRocket.getSessionURL(sessionURL => {
					Sentry.withScope(scope => {
						scope.setExtra('sessionURL', sessionURL);
					});
				});
			}
			resolve();
		});
	};
}

export const appConfig: ApplicationConfig = {
	providers: [
		provideExperimentalZonelessChangeDetection(),
		provideRouter(
			routes,
			withComponentInputBinding(),
			withRouterConfig({
				onSameUrlNavigation: 'reload',
				urlUpdateStrategy: 'eager',
			}),
			withInMemoryScrolling({
				anchorScrolling: 'enabled',
				scrollPositionRestoration: 'enabled',
			}),
			withViewTransitions()
		),
		provideAnimations(),
		provideHttpClient(
			withFetch(),
			withInterceptors([requestInterceptor, authInterceptor])
		),
		provideTranslateService({
			defaultLanguage: environment.DEFAULT_LANGUAGE,
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient],
			},
		}),
		provideClientHydration(
			withEventReplay(),
			withHttpTransferCacheOptions({
				includePostRequests: true,
				includeRequestsWithAuthHeaders: false,
			})
		),
		provideStore({ router: routerReducer }),
		provideRouterStore({ serializer: CustomRouterStateSerializer }),
		provideEffects(RouterEffects),
		provideMarkdown(),
		provideNgxStripe(environment.STRIPE_KEY_PUBLIC),
		{
			provide: DATE_PIPE_DEFAULT_OPTIONS,
			useValue: {
				dateFormat: 'medium',
				timezone: 'America\\New_York',
			},
		},
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: { appearance: 'outline' },
		},
		{
			provide: LOCALE_ID,
			deps: [LanguageService],
			useFactory: (languageService: LanguageService) =>
				languageService.languageSignal(),
		},
		{
			provide: APP_INITIALIZER,
			useFactory: initializeApp,
			deps: [LanguageService, ThemeService, AuthService],
			multi: true,
		},
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler(),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
		},
	],
};
